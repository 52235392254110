<template>
  <DiagnosticRowItem
    ref="diagnosticRowItem"
    icon="ph-at"
    :title="$t('techDiagnosis.DNS.title')"
    :kindPlural="$t('techDiagnosis.DNS.domains')"
    :diagnostic="domainsDiagnostic"
    :tooltipText="$t('techDiagnosis.DNS.tooltipText')"
    :loading="loading || loadingDnsInfo"
  >
    <template v-slot:table>
      <slot name="top" />
      <Alert class="mx-3" color="info" dense>
        <div class="blue--text">
          {{ $t("techDiagnosis.DNS.knowMore") }}
          <a
            target="_blank"
            class="blue--text text-decoration-underline font-weight-black"
            href="https://conectasuite.com/perguntas-frequentes/conecta-sign/como-deixar-seu-dominio-seguro-no-google-workspace"
          >
            {{ $t("techDiagnosis.DNS.FAQ") }}</a
          >. {{ $t("techDiagnosis.DNS.chatSupport") }}
        </div>
      </Alert>

      <v-row justify="center" class="px-3" no-gutters>
        <v-expansion-panels flat accordion focusable v-model="panels">
          <v-expansion-panel
            v-for="(info, domain) in domainsDnsInfo"
            :key="domain"
            class="mt-2 outlined"
          >
            <v-expansion-panel-header>
              <v-list-item class="pr-5 pl-0">
                <v-list-item-icon>
                  <v-tooltip bottom :color="info.color">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" :color="info.color">{{
                        info.icon
                      }}</v-icon>
                    </template>
                    <div style="max-width: 270px; text-align: justify">
                      {{ info.tooltip }}
                    </div>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-title class="text-justify">
                  {{ $t("techDiagnosis.DNS.domain") }} <b>{{ domain }}</b>
                </v-list-item-title>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-5 elevation-0">
              <v-row
                v-for="(res, i) in info.data"
                :key="i"
                class="pb-0 font-weight-medium mt-3"
                no-gutters
              >
                <!-- <v-col class="py-0 px-1">{{ messages[res.code] }} </v-col> -->
                <v-col class="pb-0">
                  <v-list-item class="px-7">
                    <v-icon :color="res.color">{{ res.icon }}</v-icon>
                    <div class="text-justify px-5">
                      {{ messages[res.code] }}
                      <a
                        v-if="
                          [
                            'google_spf_non_standard',
                            'missing_spf_dns_txt',
                          ].includes(res.code)
                        "
                        class="blue--text text-decoration-none px-5"
                        href="https://conectasuite.com/perguntas-frequentes/conecta-sign/importancia-do-registro-spf/"
                        target="_blank"
                        >{{ $t("techDiagnosis.DNS.helpArticle") }}</a
                      >
                    </div>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row class="font-weight-medium mt-4" no-gutters>
                <v-col class="pb-0">
                  <v-list-item class="px-7 d-flex flex-wrap">
                    <v-icon v-if="info.dkim.code" :color="info.dkim.color">{{
                      info.dkim.icon
                    }}</v-icon>
                    <v-icon v-else color="orange">ph-fill ph-warning</v-icon>
                    <div class="px-5">
                      {{ messages[info.dkim.code] || messages["missing_dkim"] }}
                    </div>
                    <v-tooltip top v-if="!showEdit(domain)">
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          v-if="!showEdit(domain)"
                          class="blue--text text-decoration-none"
                          target="_blank"
                          v-bind="attrs"
                          v-on="on"
                          @click="updateEditShow(domain, true)"
                          >{{ $t("techDiagnosis.DNS.editSelector") }}</a
                        >
                      </template>
                      <span> {{ $t("techDiagnosis.DNS.selectorInfo") }} </span>
                    </v-tooltip>
                    <div
                      v-if="showEdit(domain)"
                      class="pt-3 pb-0 d-flex align-center flex-wrap"
                    >
                      <v-text-field
                        :label="$t('techDiagnosis.DNS.selector')"
                        v-model="selectors[domain]"
                        dense
                        color="blue"
                        flat
                        :height="42"
                        :loading="loadingDnsInfo"
                        :rules="[(value) => !!value]"
                      ></v-text-field>
                      <v-btn
                        small
                        text
                        @click="
                          updateEditShow(domain, false);
                          selectors[domain] = dkimSelector;
                        "
                        class="mr-2"
                        :disabled="loadingDnsInfo"
                        >{{ $t("action.cancel") }}</v-btn
                      >
                      <v-btn
                        small
                        text
                        class="blue--text"
                        @click="getDkimInfo(domain)"
                        :disabled="loadingDnsInfo || !selectors[domain]"
                        >{{ $t("action.search") }}</v-btn
                      >
                    </div>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-expansion-panels flat class="mt-3 mb-5">
                <v-expansion-panel>
                  <v-expansion-panel-header class="pa-0 mx-0">
                    <v-list-item class="pl-4 ma-0 py-0">
                      <v-list-item-icon class="mr-3 pr-0 py-0">
                        <v-icon size="30" class="blue--text">
                          ph-magnifying-glass
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          class="font-weight-regular text-wrap"
                        >
                          {{ $t("techDiagnosis.DNS.spamVerify") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item class="px-7">
                      <v-icon class="blue--text px-5"
                        >ph-list-magnifying-glass</v-icon
                      >
                      {{ $t("techDiagnosis.DNS.useThe") }}
                      <a
                        class="ms-2 blue--text font-weight-black"
                        target="_blank"
                        :href="linkMultiBRL(domain)"
                      >
                        MultiRBL</a
                      >
                    </v-list-item>
                    <v-list-item class="px-7">
                      <v-icon class="blue--text px-5"
                        >ph-list-magnifying-glass</v-icon
                      >
                      {{ $t("techDiagnosis.DNS.useThe") }}
                      <a
                        class="ms-2 blue--text text-decoration-underline font-weight-black"
                        target="_blank"
                        :href="linkMxToolBox(domain)"
                      >
                        MxToolBox</a
                      >
                    </v-list-item>
                    <v-list-item class="px-10">
                      <Alert dense color="info" class="mt-3">
                        {{ $t("techDiagnosis.DNS.seeOur") }}
                        <a
                          class="blue--text text-decoration-underline font-weight-black"
                          href="https://conectasuite.com/perguntas-frequentes/conecta-sign/como-deixar-seu-dominio-seguro-no-google-workspace/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >FAQ
                        </a>
                        {{ $t("techDiagnosis.DNS.knowMoreBlackLists") }}
                      </Alert>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-row class="body-2 px-5 pb-4" no-gutters>
                <v-col class="pb-0">
                  {{ $t("techDiagnosis.DNS.knowMoreDomains") }}
                  <a
                    class="blue--text text-decoration-none"
                    :href="`https://toolbox.googleapps.com/apps/checkmx/check?domain=${domain}&dkim_selector=`"
                    target="_blank"
                    >Google Admin Toolbox.</a
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-for="domain in ignoredDomains"
            :key="domain"
            class="mt-2 outlined"
            readonly
          >
            <v-expansion-panel-header>
              <v-list-item class="pr-5 pl-0">
                <v-list-item-icon>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="grey"
                        >ph-prohibit</v-icon
                      >
                    </template>
                    <div style="max-width: 270px">
                      {{ $t("techDiagnosis.DNS.ignoredDomainText") }}
                    </div>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-title class="text-justify">
                  {{ $t("techDiagnosis.DNS.domain") }}
                  <b>{{ domain }}</b>
                </v-list-item-title>
                <v-list-item-action
                  class="ma-0 d-flex align-center justify-center"
                >
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><v-chip x-small>
                          {{ $t("techDiagnosis.DNS.ignoredVerify") }}
                        </v-chip></span
                      >
                    </template>
                    <div style="max-width: 270px">
                      {{ $t("techDiagnosis.DNS.ignoredDomainText") }}
                    </div>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </DiagnosticRowItem>
</template>

<script>
import DiagnosticRowItem from "@/components/general/DiagnosticRowItem.vue";
import { BAD, OK, WARNING } from "@/helpers/variables/diagnostic";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DNS",
  components: { DiagnosticRowItem },
  props: {
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    showDomainInfo: false,
    selectorsShowEdit: {},
    selectors: {},
    panels: [0],
    loadingDnsInfo: false,
    domainsDnsInfo: {},
    dkimSelector: "google",
    statusColors: {},
    statusIcons: {},
    resStatus: {
      error: BAD,
      warning: WARNING,
      success: OK,
    },
  }),
  computed: {
    ...mapGetters(["mainDomain", "domains", "getUsedDomainsToCheckDNS"]),

    messages() {
      return {
        google_mx_records_not_found: this.$t(
          "techDiagnosis.DNS.googleMxRecordsNotFound"
        ),
        priority_mx_record_found: this.$t(
          "techDiagnosis.DNS.priorityMxRecordFound"
        ),
        all_mx_records_configured: this.$t(
          "techDiagnosis.DNS.allMxRecordsConfigured"
        ),
        missing_google_mx_records: this.$t(
          "techDiagnosis.DNS.missingGoogleMxRecords"
        ),
        google_standard_spf: this.$t("techDiagnosis.DNS.googleStandardSpf"),
        google_spf_non_standard: this.$t(
          "techDiagnosis.DNS.googleSpfNonStandard"
        ),
        missing_spf_dns_txt: this.$t("techDiagnosis.DNS.missingSpfDnsTxt"),
        found_dkim: this.$t("techDiagnosis.DNS.foundDkim"),
        missing_dkim: this.$t("techDiagnosis.DNS.missingDkim"),
        missing_domain_data: this.$t("techDiagnosis.DNS.missingDomainData"),
      };
    },

    alertMessages() {
      return {
        [BAD]: this.$t("techDiagnosis.DNS.alertMessages.BAD"),
        [WARNING]: this.$t("techDiagnosis.DNS.alertMessages.WARNING"),
        [OK]: this.$t("techDiagnosis.DNS.alertMessages.OK"),
      };
    },

    showEdit() {
      return (domain) => this.selectorsShowEdit[domain];
    },

    error() {
      return (dnsInfo) => dnsInfo.some((info) => info.type == "error");
    },

    dkimResponse() {
      return (dnsInfo) => dnsInfo.find((info) => info.code == "found_dkim");
    },

    warning() {
      return (dnsInfo) =>
        dnsInfo.find((info) => info.type == "warning") ||
        !this.dkimResponse(dnsInfo);
    },

    ignoredDomains() {
      return this.domains.filter(
        (domain) => !this.getUsedDomainsToCheckDNS.includes(domain)
      );
    },

    domainsDiagnostic() {
      let count = 0;
      const domainsDnsInfo = this.domainsDnsInfo || {};
      const status = this.domains.reduce((status, domain) => {
        if (this.ignoredDomains.includes(domain)) {
          return OK;
        }
        const dnsInfo = domainsDnsInfo[domain] || { status: BAD };
        if (status == BAD || dnsInfo.status == BAD) {
          count++;
          return BAD;
        } else if (status == WARNING || dnsInfo.status == WARNING) {
          count++;
          return WARNING;
        } else {
          return OK;
        }
      }, OK);
      return {
        status,
        count,
      };
    },
  },
  watch: {
    domainsDnsInfo() {
      this.$emit("update-dns-diagnostic", this.domainsDiagnostic);
    },
    getUsedDomainsToCheckDNS() {
      this.getDomainsDnsInfo();
    },
  },
  methods: {
    ...mapMutations(["setSnackBar"]),

    defaultDnsInfo(domain) {
      return {
        data: [],
        status: WARNING,
        color: "warning",
        icon: 'ph-fill ph-warning-circle"',
        domain: domain,
        tooltip: "",
      };
    },

    async getDnsInfo(payload) {
      const body = payload || {
        domain: this.mainDomain,
        dkim_selector: this.dkimSelector,
      };
      const url = process.env.VUE_APP_CLOUD_FUNCTION_VALIDATE_DNS;
      await this.$axios
        .post(url, body)
        .then(({ data }) => {
          this.domainsDnsInfo = {
            ...this.domainsDnsInfo,
            [body.domain]: this.summaryDnsInfo(data || [], body.domain),
          };
        })
        .catch((err) => {
          console.error(err);
          this.domainsDnsInfo = {
            ...this.domainsDnsInfo,
            [body.domain]: this.defaultDnsInfo(body.domain),
          };
        });
    },

    async getDkimInfo(domain) {
      this.loadingDnsInfo = true;
      await this.getDnsInfo({
        domain,
        dkim_selector: this.selectors[domain],
      });
      this.updateEditShow(domain, false);
      this.loadingDnsInfo = false;
    },

    async getDomainsDnsInfo() {
      this.loadingDnsInfo = true;
      const selector = this.selectors[this.mainDomain] || this.dkimSelector;
      if (this.getUsedDomainsToCheckDNS?.length > 0) {
        for (const domain of this.getUsedDomainsToCheckDNS) {
          await this.getDnsInfo({
            domain,
            dkim_selector: selector,
          });
        }
      }
      for (const domain in this.selectorsShowEdit) {
        this.updateEditShow(domain, false);
      }
      this.loadingDnsInfo = false;
    },

    updateEditShow(domain, value) {
      this.selectorsShowEdit = { ...this.selectorsShowEdit, [domain]: value };
    },

    linkMultiBRL(domain) {
      return `https://multirbl.valli.org/lookup/${domain}`;
    },

    linkMxToolBox(domain) {
      return `https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3a${domain}&run=toolpage`;
    },

    summaryDnsInfo(data, domain) {
      let status = OK;
      if (this.error(data)) {
        status = BAD;
      } else if (this.warning(data)) {
        status = WARNING;
      }
      const info = data.map((res) => {
        const status = this.resStatus[res.type] || OK;
        return {
          ...res,
          color: res.type,
          status,
          icon: this.statusIcons[status],
        };
      });
      return {
        data: info.filter((res) => res.code != "found_dkim"),
        dkim: info.filter((res) => res.code == "found_dkim")[0] || {},
        status,
        color: this.statusColors[status],
        icon: this.statusIcons[status],
        domain,
        tooltip: this.alertMessages[status] || OK,
      };
    },
  },
  beforeMount() {
    for (const domain of this.domains) {
      this.selectors[domain] = this.dkimSelector;
      this.updateEditShow(domain, false);
    }
  },
  mounted() {
    this.statusColors = this.$refs.diagnosticRowItem.statusColors;
    this.statusIcons = this.$refs.diagnosticRowItem.statusIcons;
    this.getDomainsDnsInfo();
  },
};
</script>

<style scoped>
.v-expansion-panel-header {
  padding: 8px 12px !important;
}

.negative-margin {
  margin-top: -8px !important;
}
</style>
